import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import queryString from 'query-string';

export async function basicGet<T>(axios: AxiosInstance, url: string, params?: Record<string, any>) {
	try {
		const { data } = await axios.get<T>(addParamsToUrl(url, params));
		return data;
	} catch (error: any) {
		throw JSON.parse(JSON.stringify(error.response));
	}
}
export async function get<T>(axios: AxiosInstance, url: string, config?: AxiosRequestConfig) {
	try {
		const { data } = await axios.get<T>(url, config);
		return data;
	} catch (error: any) {
		throw JSON.parse(JSON.stringify(error.response));
	}
}

export async function basicGetWithResponseHeaders<T>(
	axios: AxiosInstance,
	url: string,
	params?: Record<string, any>,
	config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> {
	try {
		const response = await axios.get<T>(url, {
			params,
			...config
		});
		return response;
	} catch (error: any) {
		throw JSON.parse(JSON.stringify(error.response));
	}
}

export async function basicPost<T>(axios: AxiosInstance, url: string, body?: any, params?: Record<string, any>) {
	try {
		const { data } = await axios.post<T>(addParamsToUrl(url, params), body);
		return data;
	} catch (error: any) {
		throw JSON.parse(JSON.stringify(error.response));
	}
}

export async function basicPut<T>(axios: AxiosInstance, url: string, body: any, params?: Record<string, any>) {
	try {
		const { data } = await axios.put<T>(addParamsToUrl(url, params), body);
		return data;
	} catch (error: any) {
		throw JSON.parse(JSON.stringify(error.response));
	}
}

export async function basicDelete<T>(axios: AxiosInstance, url: string, params?: Record<string, any>) {
	try {
		const { data } = await axios.delete<T>(addParamsToUrl(url, params));
		return data;
	} catch (error: any) {
		throw JSON.parse(JSON.stringify(error.response));
	}
}

export async function deleteWithBodyRequest<T>(axios: AxiosInstance, url: string, body: any) {
	try {
		const { data } = await axios.delete<T>(url, { data: body });
		return data;
	} catch (error: any) {
		throw JSON.parse(JSON.stringify(error.response));
	}
}

export type FormEntities = {
	name: string;
	entities: any[];
};

export async function basicUpload<T>(
	axios: AxiosInstance,
	url: string,
	entitiesList: FormEntities[],
	params?: Record<string, any>
) {
	const formData = new FormData();
	entitiesList.forEach(formEntities => formEntities.entities.forEach(e => formData.append(formEntities.name, e)));

	try {
		const { data } = await axios.post<T>(addParamsToUrl(url, params), formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
		return data;
	} catch (error: any) {
		throw JSON.parse(JSON.stringify(error.response));
	}
}

function addParamsToUrl(url: string, params?: Record<string, any>) {
	return params ? url + '?' + queryString.stringify({ ...params }) : url;
}
