import { deepMerge } from '../utils/deepMerge';

import fi from '../locales/fi.json';
import en from '../locales/en.json';
import sv from '../locales/sv.json';
import de from '../locales/de.json';
import fi_dev from '../locales/fi_dev.json';
import sv_dev from '../locales/sv_dev.json';
import de_dev from '../locales/de_dev.json';
import { z } from 'zod';

export const supportedLocaleSchema = z.enum(['en', 'fi', 'sv', 'de']);
export const feelisSupportedLocaleSchema = z.enum(['en', 'fi', 'sv', 'de', 'fr', 'es', 'nl']);

export type SupportedLocale = z.infer<typeof supportedLocaleSchema>;
export type FeelisSupportedLocale = z.infer<typeof feelisSupportedLocaleSchema>;
export type LocaleStrings = typeof en;
export const defaultLocale: SupportedLocale = 'en';
export const supportedLocales: SupportedLocale[] = supportedLocaleSchema.options;
export const feelisSupportedLocales: FeelisSupportedLocale[] = feelisSupportedLocaleSchema.options;
export const nonDefaultLocales: SupportedLocale[] = supportedLocales.filter(l => l !== defaultLocale);

export function toCountryCode(locale: SupportedLocale) {
	switch (locale) {
		case 'sv':
			return 'SE';
		case 'en':
			return 'GB';
		default:
			return locale.toUpperCase();
	}
}

/**
 * Merges "official" translations with the unchecked dev versions of strings
 *
 * @See assertLocalizationKeys.test.ts
 */
export function getLocaleStrings(locale: SupportedLocale): LocaleStrings {
	switch (locale) {
		case 'en':
			return en;
		case 'fi':
			// getting compile errors when locales are missing is annoying - assertLocalizationKeys.test.ts
			// will still make sure the translations are present
			return (deepMerge(fi, fi_dev) as any) as LocaleStrings;
		case 'sv':
			return (deepMerge(sv, sv_dev) as any) as LocaleStrings;
		case 'de':
			return (deepMerge(de, de_dev) as any) as LocaleStrings;
		default:
			throw new Error('Unsupported locale:' + locale);
	}
}

/**
 * Maps a given locale string to a supported locale type.
 * If the locale is not supported, it falls back to a default.
 */
export const mapToSupportedLocale = (locale: string, fallback: FeelisSupportedLocale = 'en'): FeelisSupportedLocale => {
	return feelisSupportedLocaleSchema.safeParse(locale).success ? (locale as FeelisSupportedLocale) : fallback;
};
