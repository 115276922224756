import React, { lazy, Suspense, useMemo } from 'react';
import { keycloakClientFactory } from './api/apiSettings';
import { getSessionStoreState } from './stores/session/SessionStore';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import DelaySpinner from './components/delay-spinner/DelaySpinner';

const ExternalAppRouter = lazy(() => import('./ExternalAppRouter'));
const AppRouter = lazy(() => import('./AppRouter'));

export function App() {
	const keycloakClient = useMemo(() => {
		const currentPath = window.location.pathname;
		const externalPathPattern = /^\/external(\/[a-zA-Z0-9-]+)?$/;

		if (externalPathPattern.test(currentPath)) {
			return null;
		}
		return keycloakClientFactory();
	}, []);

	return (
		<Suspense fallback={<DelaySpinner type="default" delay={300} />}>
			{keycloakClient ? (
				<ReactKeycloakProvider
					authClient={keycloakClient}
					initOptions={{ onLoad: 'login-required', checkLoginIframe: false }}
					onTokens={({ token }) => {
						getSessionStoreState().setToken(token);
					}}>
					<AppRouter />
				</ReactKeycloakProvider>
			) : (
				<ExternalAppRouter />
			)}
		</Suspense>
	);
}
